import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';
import RichText from 'components/RichText';
import Reveal from 'react-reveal/Reveal';

const HeroContainer = styled.header`
  position: relative;
  
  & + * {
    margin-top: 8rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 12rem;
    }
  }
`;

const HeroImage = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.32);
  }
`;

const HeroInner = styled(Inner)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 12.8rem;
  padding-bottom: 12rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-top: 21.4rem;
  }
`;

const HeroWrap = styled(Wrap)`
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 2.4rem;
  flex: 2 1 100%;
  color: #fff;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 100%;
    width: 0.2rem;
    height: 100%;
    background-color: ${colors.red600};
    transform: translateY(-4.8rem) scaleY(0);
    transform-origin: top center;
    transition: transform 1s cubic-bezier(0.22, 1, 0.36, 1) 0.22s;
  }
  
  .drawDown & {
    &::before {
      transform: translateY(0) scaleY(1);
    }
  } 
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    max-width: 107.2rem;
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    &::before {
      padding-left: 0;
      right: calc(100% + 3.2rem);
    }
  }
`;

const HeroCategory = styled.span`
  display: block;
  margin-bottom: 1.6rem;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 2.4rem; 
  }
`;

const HeroAuthor = styled.span`
  display: block;
  font-size: 1.4rem;
  margin-top: 1.2rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 1.8rem; 
  }
`;

class InsightsHero extends Component {
  render() {
    return (
      <HeroContainer>
        {this.props.background && (
          <HeroImage>
            <img
              src={this.props.background.url}
              alt={this.props.background.alt}
            />
          </HeroImage>
        )}

        <HeroInner>
          <Reveal effect='drawDown'>
            <HeroWrap>
              <div>
                {this.props.category && <HeroCategory>{`#${this.props.category}`}</HeroCategory>}

                {this.props.title && <RichText render={this.props.title}/>}

                {this.props.author && <HeroAuthor>By {this.props.author}</HeroAuthor>}
              </div>
            </HeroWrap>
          </Reveal>
        </HeroInner>
      </HeroContainer>
    );
  }
}

export default InsightsHero;
