import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';
import SectionHeading from "components/_ui/SectionHeading";
import CaseStudy from 'components/_card/CaseStudy';

const RelatedStudyContainer = styled.div`
  header {
    & + * {
      margin-top: 5.6rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    header {
      & + * {
        margin-top: 8rem;
      }
    }
  }
`;

class RelatedStudy extends Component {
  render() {
    const { study } = this.props;

    return (
      <RelatedStudyContainer>
        <header>
          <Inner>
            <Wrap>
              <SectionHeading>Related case study</SectionHeading>
            </Wrap>
          </Inner>
        </header>

        <CaseStudy
          area={study.area}
          name={study.client_name}
          image={study.feature_image}
          tagline={study.tagline}
          uid={study._meta.uid}
          align='left'
        />
      </RelatedStudyContainer>
    );
  }
}

export default RelatedStudy;
