import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import InsightsHero from 'components/_page/insights/Hero';
import SliceZone from "../components/SliceZone";
import RelatedStudy from "components/_page/insights/RelatedStudy";
import GetInTouch from 'components/GetInTouch';
import Related from 'components/Related';

const RenderBody = ({ insight, site }) => (
  <>
    <InsightsHero
      title={insight.title}
      background={insight.feature_image}
      category={insight.category && insight.category.name}
      author={insight.author && insight.author.name}
    />

    {(insight.body && insight.body.length > 0) && (
      <SliceZone
        body={insight.body}
      />
    )}

    {insight.related_case_study && <RelatedStudy study={insight.related_case_study}/>}

    <GetInTouch
      title={insight.get_in_touch_heading || site.get_in_touch_heading || 'Everything starts with a conversation.'}
      signpost="View more"
    />

    {(insight.related_insights && insight.related_insights.length > 0 && insight.related_insights[0].insight) && (
      <Related
        heading='More insights'
        type='insight'
        cards={insight.related_insights.slice(0, 3)}
      />
    )}
  </>
);

export default ({ data }) => {
  const insight = data.prismic.insightByUID;
  const site = data.prismic.allSite_settingss;

  if (!insight) return null;

  return (
    <Layout>
      <SEO
        title={insight.social_title || (insight.title && insight.title[0].text) || 'Insight'}
        description={insight.social_description ? insight.social_description : null}
        image={insight.social_image ? insight.social_image.url : null}
      />

      <RenderBody
        insight={insight}
        site={site}
      />
    </Layout>
  );
}

export const query = graphql`
  query InsightQuery($uid: String!) {
    prismic {
      insightByUID: insight(uid: $uid, lang: "en-us") {
        title
        category {
          ... on PRISMIC_Category {
            name
          }
        }
        author {
          ... on PRISMIC_Person {
            name
          }
        }
        feature_image
        related_case_study {
          ... on PRISMIC_Case_study {
            client_name
            tagline
            feature_image
            area {
              ... on PRISMIC_Area {
                name
              }
            }
            _meta {
              uid
            }
          }
        }
        body {
          ... on PRISMIC_InsightBodyText {
            type
            primary {
              content
              alignment
            }
          }
          ... on PRISMIC_InsightBodyCustom_html {
            type
            primary {
              content
            }
          }
          ... on PRISMIC_InsightBodyQuote {
            type
            primary {
              quote {
                ... on PRISMIC_Quote {
                  content
                  name
                  title
                }
              }
            }
          }
          ... on PRISMIC_InsightBodyContent_block {
            type
            primary {
              image
              caption
              rubric
              content
            }
          }
          ... on PRISMIC_InsightBodyCallout {
            type
            primary {
              callout
              rubric
              content
            }
          }
        }
        related_insights {
          insight {
            ... on PRISMIC_Insight {
              _meta {
                uid
              }
              title
              feature_image
              category {
                ... on PRISMIC_Category {
                  name
                }
              }    
            }
          }
        }
        get_in_touch_heading
        social_title
        social_description
        social_image
      }
      allSite_settingss {
        edges {
          node {
            get_in_touch_heading
          }
        }
      }
    }
  }
`;
